import makeStyles from '@material-ui/core/styles/makeStyles';
import { get } from 'lodash';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.shape.componentSpacingTopBottom,
    backgroundColor: (params) => get(params, 'backgroundColor', ''),
    '& .heading': {
      marginBottom: pxToRem(16),
      color: (params) => get(params, 'fontColor', 'inherit'),
    },
    '& .MuiTypography-body1': {
      color: theme.palette.text.darkGrey,
    },
    '& .descriptionArea': {
      color: (params) => get(params, 'fontColor', 'inherit'),
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.paddingRight(pxToRem(76)),
        ...theme.mixins.paddingLeft(0),
      },
      '& .richText': {
        '& p:last-child': {
          marginBottom: 0,
        },
      },
    },

    '& .rowWrapper': {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },

      '& .close-btn': {
        top: theme.spacing(-2),
        ...theme.mixins.right(theme.spacing(-2)),
        background: theme.palette.secondary.main,
        border: theme.borders.divider,
        [theme.breakpoints.up('lg')]: {
          top: theme.spacing(-3),
          ...theme.mixins.right(theme.spacing(-3)),
        },

        '&:hover': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },

      '&.rowReverse': {
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row-reverse',
        },
        '& .descriptionArea': {
          [theme.breakpoints.up('lg')]: {
            ...theme.mixins.paddingLeft(pxToRem(61)),
            ...theme.mixins.paddingRight(pxToRem(29)),
          },
        },
      },
    },
    '& .sitecorelink': {
      width: 'fit-content',
    },
  },

  button: {
    marginTop: pxToRem(36),
    ...theme.mixins.marginRight(pxToRem(16)),
    '& .btn-txt': {
      ...theme.palette.common.ellipsis(1),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(16),
    },
  },

  button2: {
    marginTop: pxToRem(36),
    '& .btn-txt': {
      ...theme.palette.common.ellipsis(1),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(16),
    },
  },

  mediaArea: {
    marginBottom: pxToRem(30),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
    '& .plyr .plyr__video-wrapper': {
      paddingBottom: '66.67% !important',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      '& > iframe': {
        height: '100%',
      },
    },
  },

  newVariationRoot: {
    paddingTop: pxToRem(80),
    paddingBottom: pxToRem(80),
    paddingLeft: pxToRem(92),
    paddingRight: pxToRem(92),
    [theme.breakpoints.down('md')]: {
      paddingTop: pxToRem(28),
      paddingBottom: pxToRem(28),
      paddingLeft: pxToRem(0),
      paddingRight: pxToRem(0),
    },

    backgroundColor: (params) => get(params, 'backgroundColor', ''),
    '& .heading': {
      marginBottom: pxToRem(16),
      color: (params) => get(params, 'fontColor', 'inherit'),
    },
    '& .MuiTypography-body1': {
      color: theme.palette.text.darkGrey,
    },
    '& .descriptionArea': {
      color: (params) => get(params, 'fontColor', 'inherit'),
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.paddingLeft(0),
      },
      '& .richText': {
        '& p:last-child': {
          marginBottom: 0,
        },
      },
      '& p': {
        ...theme.palette.common.ellipsis(21),
      },
    },

    '& .rowWrapper': {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },

      '&.rowReverse': {
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row-reverse',
        },
      },
    },
    '& .sitecorelink': {
      width: 'fit-content',
    },
  },

  multiImageVariation: {
    [theme.breakpoints.down('md')]: {
      paddingTop: pxToRem(56),
      paddingBottom: pxToRem(0),
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
    },
  },

  sectionTitle: {
    width: '100%',
    '& h2': {
      ...theme.palette.common.ellipsis(3),
      [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(32),
      },
    },
  },

  articleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  textArea: {
    width: '31.850%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: pxToRem(32),
      paddingBottom: pxToRem(0),
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
    },
  },

  textFocusedArea: {
    width: '61.72%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: pxToRem(32),
      paddingBottom: pxToRem(0),
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
    },
  },
  descPara: {
    paddingTop: pxToRem(32),
    [theme.breakpoints.down('md')]: {
      paddingTop: pxToRem(0),
    },
  },

  multipleImages: {
    paddingTop: pxToRem(56),
    paddingBottom: pxToRem(56),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingTop: pxToRem(32),
      paddingBottom: pxToRem(32),
    },
  },

  largeImage: {
    width: '65.889%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  mediumImage: {
    width: '61.6525%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .video-picture-tag': {
      '& .pictureWithPoster': {
        '&.pictureWithArticle': {
          paddingTop: '0%',
          [theme.breakpoints.down('lg')]: {
            paddingTop: '56.25%',
          },
        },
        paddingTop: '0%',
        [theme.breakpoints.down('lg')]: {
          paddingTop: '75%',
        },
      },
      '& .plyr': {
        height: '100%',
      },
      '& .plyr iframe': {
        height: '100%',
      },
    },
  },
  smallImage: {
    width: '31.850%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: pxToRem(8),
    },
  },

  multiMediaArea: {
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      height: '400px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '435px',
    },
    [theme.breakpoints.up('xxl')]: {
      height: '600px',
    },
    [theme.breakpoints.up('xxxl')]: {
      height: '660px',
    },
    '& .plyr .plyr__video-wrapper': {
      paddingBottom: '66.67% !important',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      '& > iframe': {
        height: '100%',
      },
    },
    '& .video-picture-tag': {
      '&, & > .plyr': {
        height: '100%',
      },
    },
  },
  multiButton: {
    marginTop: pxToRem(32),
    '& .btn-txt': {
      ...theme.palette.common.ellipsis(1),
    },
  },

}));

export default useStyles;
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import useStyles from './style';
import Button from '../Button';
import DctSvgIcon from '../Svg';
import Picture from '../Picture';
import { Helmet } from 'react-helmet';

const CustomVideo = props => {
  // this component has playIcon props also which is
  // responsible to handle custom play button
  // we need to pass icon image URL as playIcon prop
  const {
    video,
    posterImage,
    mobileImage,
    setPlayerDOMRef,
    closeButtonSize,
    aspectRatio,
    alternateAltText,
    shouldAutoplay = true,
    from = 'other',
  } = props;
  const classes = useStyles(props);
  const videoURL = `${get(video, `value.${from !== 'DualHeroSlider' ? 'href' : 'text'}`)}${shouldAutoplay ? '?autoplay=1&loop=1' : ''}`;

  const videoLDJson = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    'contentUrl': video.value.href,
  }

  return (
    <div className={`${classes.root} video-picture-tag ${from}`}>
      {from !== 'DualHeroSlider' &&
        <Picture
          className="pictureWithPoster pictureWithArticle"
          media={{
            image: posterImage,
            mobileImage,
            aspectRatio,
            disableLazyLoad: true,
          }}
          isParalax={false}
          alternateAltText={alternateAltText}
        />
      }
      <Helmet>
        <script type="application/ld+json">{`${JSON.stringify(videoLDJson)}`}</script>
      </Helmet>
      <div
        className={`${classes.wrapper} plyr__video-embed plyr-video`}
        ref={ref => setPlayerDOMRef && setPlayerDOMRef(ref)}
        data-plyr-embed-id={videoURL}
        data-plyr-provider="youtube"
      ></div>
      {from !== 'DualHeroSlider' &&
        <Button
          iconOnly
          color="inherit"
          className={clsx('close-btn plyr__element--hide', closeButtonSize)}
        >
          <DctSvgIcon name="CloseIcon" />
        </Button>
      }
    </div>
  );
};

export default CustomVideo;

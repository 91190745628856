import React, { useState, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '../../../components/atoms/Typography';
import useStyles from './style.js';
import ImageVideoComponent from '../ImageVideoComponent';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

const ArticleCarousel = ({
  aspectRatio,
  sitecoreContext,
  pageName,
  componentName,
  heading,
  title,
  mediaGallery,
}) => {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('fadeIn');
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const articleWrapper = useRef(null);

  const changeImage = index => {
    setTimeout(() => {
      setImageIndex(index);
    }, 350);
    imageIndex !== index && setAnimationClass('fadeOut');

    setTimeout(() => {
      setAnimationClass('fadeIn');
      setTimeout(() => {
        articleWrapper.current.querySelectorAll('.close-btn').forEach(btn => {
          btn.click();
        });
      }, 300);
    }, 300);
  };

  const Slider = () => {
    return mediaGallery && !isEmpty(mediaGallery) && mediaGallery.map((image, index) => (
      <div className={classes.sliderBox} onClick={() => changeImage(index)} key={index}>
        <Typography
          variant={isMobile ? 'body5' : 'body1Bold22'}
          className={
            imageIndex === index ? `${classes.font} selected` : classes.font
          }
        >
          <Text field={{ value: `0${index + 1}` }} />
        </Typography>

        <div className={classes.carouselBorder}>
          {imageIndex === index ? (
            <div className={classes.carouselInnerBorder}></div>
          ) : null}
        </div>
      </div>
    ));
  };


  return (
    <div>
      <div className={classes.articleCarouselWrapper} ref={articleWrapper}>
        {mediaGallery.map((media, index) => (
          <div className={clsx(index === imageIndex ? classes.fadeIn : classes.fadeOut, classes.articleCarousel)} key={index}>
            <ImageVideoComponent
              mediaField={media.fields}
              aspectRatio={aspectRatio}
              sitecoreContext={sitecoreContext}
              heading={heading}
              title={title}
              pageName={pageName}
              componentName={componentName}
              shouldAutoplay={false}
              articleKey={Math.random()}
            />
          </div>
        ))}
      </div>
      <div className={classes.slider}>
        <Slider />
      </div>
    </div>
  );
};

export default ArticleCarousel;
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import Grid from '../../../components/molecules/Grid';
import Title from '../../../components/atoms/Title';
import Typography from '../../../components/atoms/Typography';
import Button from '../../../components/atoms/Button';
import aspectRatios from '../../../utils/AspectRatios';
import { COMPONENT_NAME } from './locators';
import useStyles from './style.js';
import { withNamespaces } from 'react-i18next';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import RichText from '../../../components/atoms/RichText';
import clsx from 'clsx';
import {
  ArticleTextRight,
  ArticleTextLeft,
  MultipleImage,
  ArticleImageFocus,
  ArticleTextFocus,
  ArticleWithCarousel,
  LEFT,
} from './constants';
import ArticleCarousel from '../../../components/organism/ArticleCarousel';
import ImageVideoComponent from '../../../components/organism/ImageVideoComponent';

const Article = props => {
  const { fields, params, sitecoreContext } = props;
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  const classes = useStyles(params);
  const variation = params.variation || '';
  const isOldVariation =
    variation === ArticleTextRight || variation === ArticleTextLeft;
  const isMultiImage = variation.includes(MultipleImage);
  const isFocusLeft = variation.includes(LEFT);
  const isImageFocus = variation.includes(ArticleImageFocus);
  const isTextFocus = variation.includes(ArticleTextFocus);
  const isWithCarousel = variation.includes(ArticleWithCarousel);
  const articleKey = Math.random();
  const {
    title,
    heading,
    description,
    media,
    cta1,
    cta2,
    SEOEnglishCTA1,
    SEOEnglishCTA2,
  } = fields;

  if (isEmpty(media)) {
    return null;
  }

  const addAnalyticsInfo = text => {
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: text,
      },
    };
    pushAnaylyticsData(obj);
  };

  return !isEmpty(fields) ? (
    <section data-locator={COMPONENT_NAME}>
      {isOldVariation && !isMultiImage && (
        <Grid className={classes.root}>
          <Grid
            container
            spacing={0}
            className={`rowWrapper ${variation !== ArticleTextLeft &&
              'rowReverse'}`}
            alignItems="center"
          >
            <Grid item xs={12} lg={6} className="descriptionArea">
              <Title text={title} />
              <Typography variant="h5Bold" component="h2" className="heading">
                <Text field={heading} />
              </Typography>
              <Typography variant="body1" component="p">
                <RichText innerHTML={description} />
              </Typography>
              {cta1 && cta1.value && cta1.value.text && (
                <Button
                  buttonType={'form'}
                  type={'submit'}
                  size={'small'}
                  hasBorder={false}
                  field={cta1}
                  className={classes.button}
                  onClick={() =>
                    addAnalyticsInfo(get(SEOEnglishCTA1, 'value', ''))
                  }
                ></Button>
              )}
              {cta2 && cta2.value && cta2.value.text && (
                <Button
                  buttonType={'form'}
                  type={'submit'}
                  size={'small'}
                  hasBorder={false}
                  field={cta2}
                  className={classes.button2}
                  onClick={() =>
                    addAnalyticsInfo(get(SEOEnglishCTA2, 'value', ''))
                  }
                ></Button>
              )}
            </Grid>
            <Grid item xs={12} lg={6} className={classes.mediaArea}>
              {!isEmpty(media) && Array.isArray(media) && (
                <ImageVideoComponent
                  mediaField={media[0].fields}
                  sitecoreContext={sitecoreContext}
                  heading={heading}
                  pageName={pageName}
                  componentName={componentName}
                  articleKey={articleKey}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isOldVariation && (
        <div
          className={clsx(classes.newVariationRoot, {
            [classes.multiImageVariation]: isMultiImage,
          })}
        >
          {!isMultiImage && isImageFocus && (
            <>
              <div
                className={`${classes.articleWrap} rowWrapper ${!isFocusLeft &&
                  'rowReverse'}`}
              >
                <div
                  className={clsx(classes.multiMediaArea, classes.mediumImage)}
                >
                  {!isEmpty(media) && Array.isArray(media) && (
                    <ImageVideoComponent
                      mediaField={media[0].fields}
                      aspectRatio={aspectRatios['5x4']}
                      sitecoreContext={sitecoreContext}
                      heading={heading}
                      pageName={pageName}
                      componentName={componentName}
                      articleKey={articleKey}
                    />
                  )}
                </div>
                <div className={`${classes.textArea} descriptionArea`}>
                  <div className={classes.sectionTitle}>
                    <Typography
                      variant="h5Bold"
                      component="h2"
                      className="heading"
                    >
                      <Text field={heading} />
                    </Typography>
                  </div>
                  <Typography
                    className={classes.descPara}
                    variant="body1"
                    component="p"
                  >
                    <RichText innerHTML={description} />
                  </Typography>
                  {cta1 && cta1.value && cta1.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta1}
                      className={classes.button}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA1, 'value', ''))
                      }
                    ></Button>
                  )}
                  {cta2 && cta2.value && cta2.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta2}
                      className={classes.button2}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA2, 'value', ''))
                      }
                    ></Button>
                  )}
                </div>
              </div>
            </>
          )}

          {!isMultiImage && isTextFocus && (
            <>
              <div
                className={`${classes.articleWrap} rowWrapper ${isFocusLeft &&
                  'rowReverse'}`}
              >
                <div
                  className={clsx(classes.multiMediaArea, classes.smallImage)}
                >
                  {!isEmpty(media) && Array.isArray(media) && (
                    <ImageVideoComponent
                      mediaField={media[0].fields}
                      aspectRatio={aspectRatios['4x3']}
                      sitecoreContext={sitecoreContext}
                      heading={heading}
                      pageName={pageName}
                      componentName={componentName}
                      articleKey={articleKey}
                    />
                  )}
                </div>
                <div className={`${classes.textFocusedArea} descriptionArea`}>
                  <div className={classes.sectionTitle}>
                    <Typography
                      variant="h5Bold"
                      component="h2"
                      className="heading"
                    >
                      <Text field={heading} />
                    </Typography>
                  </div>
                  <Typography
                    className={classes.descPara}
                    variant="body1"
                    component="p"
                  >
                    <RichText innerHTML={description} />
                  </Typography>
                  {cta1 && cta1.value && cta1.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta1}
                      className={classes.button}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA1, 'value', ''))
                      }
                    ></Button>
                  )}
                  {cta2 && cta2.value && cta2.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta2}
                      className={classes.button2}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA2, 'value', ''))
                      }
                    ></Button>
                  )}
                </div>
              </div>
            </>
          )}

          {!isMultiImage && isWithCarousel && (
            <>
              <div
                className={`${classes.articleWrap} rowWrapper ${!isFocusLeft &&
                  'rowReverse'}`}
              >
                <div className={classes.mediumImage}>
                  {!isEmpty(media) && Array.isArray(media) && (
                    <ArticleCarousel
                      aspectRatio={aspectRatios['5x4']}
                      sitecoreContext={sitecoreContext}
                      heading={heading}
                      title={title}
                      pageName={pageName}
                      componentName={componentName}
                      mediaGallery={media}
                    />
                  )}
                </div>
                <div className={`${classes.textArea} descriptionArea`}>
                  <div className={classes.sectionTitle}>
                    <Typography
                      variant="h5Bold"
                      component="h2"
                      className="heading"
                    >
                      <Text field={heading} />
                    </Typography>
                  </div>
                  <Typography
                    className={classes.descPara}
                    variant="body1"
                    component="p"
                  >
                    <RichText innerHTML={description} />
                  </Typography>
                  {cta1 && cta1.value && cta1.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta1}
                      className={classes.button}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA1, 'value', ''))
                      }
                    ></Button>
                  )}
                  {cta2 && cta2.value && cta2.value.text && (
                    <Button
                      buttonType={'form'}
                      type={'submit'}
                      size={'small'}
                      hasBorder={false}
                      field={cta2}
                      className={classes.button2}
                      onClick={() =>
                        addAnalyticsInfo(get(SEOEnglishCTA2, 'value', ''))
                      }
                    ></Button>
                  )}
                </div>
              </div>
            </>
          )}

          {isMultiImage && (
            <>
              <div className={classes.sectionTitle}>
                <Typography variant="h5Bold" component="h2" className="heading">
                  <Text field={heading} />
                </Typography>
              </div>

              <div
                className={`${classes.multipleImages
                  } rowWrapper ${!isFocusLeft && 'rowReverse'}`}
              >
                <div
                  className={clsx(classes.multiMediaArea, classes.largeImage)}
                >
                  {!isEmpty(media) && Array.isArray(media) && (
                    <ImageVideoComponent
                      mediaField={media[0].fields}
                      aspectRatio={
                        isFocusLeft ? aspectRatios['4x3'] : aspectRatios['2x3']
                      }
                      sitecoreContext={sitecoreContext}
                      heading={heading}
                      pageName={pageName}
                      componentName={componentName}
                      articleKey={articleKey}
                    />
                  )}
                </div>
                {!isEmpty(media) && Array.isArray(media) && media[1] && (
                  <div
                    className={clsx(classes.multiMediaArea, classes.smallImage)}
                  >
                    <ImageVideoComponent
                      mediaField={media[1].fields}
                      aspectRatio={
                        isFocusLeft ? aspectRatios['2x3'] : aspectRatios['4x3']
                      }
                      sitecoreContext={sitecoreContext}
                      heading={heading}
                      pageName={pageName}
                      componentName={componentName}
                      articleKey={articleKey}
                    />
                  </div>
                )}
              </div>
              <div className="descriptionArea">
                <Typography variant="body1" component="p">
                  <RichText innerHTML={description} />
                </Typography>
              </div>

              {cta1 && cta1.value && cta1.value.text && (
                <Button
                  buttonType={'form'}
                  type={'submit'}
                  size={'small'}
                  hasBorder={false}
                  field={cta1}
                  className={classes.multiButton}
                  onClick={() =>
                    addAnalyticsInfo(get(SEOEnglishCTA1, 'value', ''))
                  }
                ></Button>
              )}
              {cta2 && cta2.value && cta2.value.text && (
                <Button
                  buttonType={'form'}
                  type={'submit'}
                  size={'small'}
                  hasBorder={false}
                  field={cta2}
                  className={classes.multiButton}
                  onClick={() =>
                    addAnalyticsInfo(get(SEOEnglishCTA2, 'value', ''))
                  }
                ></Button>
              )}
            </>
          )}
        </div>
      )}
    </section>
  ) : null;
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(Article))
);